<template lang="html">
  <div class="MediaViewer"  :class="{ 'gallery-view': galleryView }">
    <SilentboxGroup class="MediaGroup" @videoFinished="handleVideoFinished">
      <SilentboxGroupItem
        class="MediaItem MediaItem--Image"
        v-for="(image, index) in images"
        :key="image.id != null ? image.id : mediaId(index)"
        :src="encodeImageURL(image.src)"
        :style="{ backgroundImage: 'url(' + encodeImageURL(image.src) + ')'}">
        <div class="MediaItem__Overlay">
          <Icon class="MediaItem__TypeIcon" name="picture-o" />
        </div>
      </SilentboxGroupItem>
      <div class="MediaItem__VideoTitle" v-for="(video, index) in videos">
        <SilentboxGroupItem
          class="MediaItem MediaItem--Video"
          :key="video.id != null ? video.id : mediaId(index)"
          :src="video.src"
          :style="{ backgroundImage: 'url(\'' + videoThumbSrc(video.src) + '\')' }">
          <div class="MediaItem__Overlay">
            <Icon class="MediaItem__TypeIcon" name="play" />
          </div>
        </SilentboxGroupItem>
        <p class="MediaItem--title" v-if="allowTitle">{{video.title}}</p>
      </div>
      <div class="MediaItem__Videoyt" v-for="(video, index) in youTubeVideo">
        <SilentboxGroupItem
          class="MediaItem MediaItem--Video"
          :key="video.id != null ? video.id : mediaId(index)"
          :src="video.src"
          :style="{ backgroundImage: 'url(\'' + youTubeVideoThumbSrc(video.src) + '\')' }">
          <div class="MediaItem__Overlay">
            <Icon class="MediaItem__TypeIcon" name="play" />
          </div>
        </SilentboxGroupItem>
      </div>
    </SilentboxGroup>
  </div>
</template>

<script>
import { parseVideoUrl } from '../../utils/parse-video-url';
import { uniqueId } from 'lodash';

import SilentboxGroup from '../vue-silentbox/components/group';
import SilentboxGroupItem from '../vue-silentbox/components/item';
import Icon from '../Icon';

export default {
  name: 'MediaViewer',

  components: {
    SilentboxGroup,
    SilentboxGroupItem,
    Icon,
  },
  methods: {
    encodeImageURL ( src ) {
      return encodeURI( src ).replace(/\(/g, '%28').replace(/\)/g, '%29');
    },
    videoThumbSrc(videoUrl) {
      // const { id } = parseVideoUrl(videoUrl);
      if (typeof videoUrl !== 'string') {
        console.error('videoUrl is not a string:', videoUrl);
        return '';
      }
      if(videoUrl == undefined) return undefined
      videoUrl = videoUrl.replace("wat-videos.s3.amazonaws.com","s3-eu-west-1.amazonaws.com/wat-thumbs")
      videoUrl = videoUrl.replace("wat-files-dev.s3.amazonaws.com","s3-eu-west-1.amazonaws.com/wat-thumbs")
      videoUrl = videoUrl.replace("experience-lab-videos","experience-labs-thumbs")
      videoUrl = videoUrl.replace("experience-lab-files","experience-labs-thumbs")
      videoUrl = videoUrl.replace("eperience-lab-files","experience-labs-thumbs")
      videoUrl = videoUrl.replace("eperience-lab-videos","experience-labs-thumbs")
      videoUrl = videoUrl.replace(".MOV","00001.png")
      videoUrl = videoUrl.replace(".mov","00001.png")
      videoUrl = videoUrl.replace(".mp4","00001.png")
      videoUrl = videoUrl.replace(".MP4","00001.png")
      return videoUrl;
    },

    youTubeVideoThumbSrc(url){
      if (url == undefined) {
        return ""
      }
      let id = url.split('v=')[1];
      let ampersandPosition = id.indexOf('&');
      if(ampersandPosition != -1) {
        id = id.substring(0, ampersandPosition);
      }

      return "https://img.youtube.com/vi/"+id+"/default.jpg"
    },

    mediaId() {
      return uniqueId();
    },
    handleVideoFinished() {
      this.$emit('videoFinished')
    }
  },

  props: {
    images: {
      type: Array,
      default: () => [],
    },

    videos: {
      type: Array,
      default: () => [],
    },

    youTubeVideo: {
      type: Array,
      default: () => [],
    },

    allowTitle: {
      type: Boolean,
      default: () => false
    },
    galleryView: {
      type: Boolean,
      default: () => false // Valor predeterminado
    }
  },
}
</script>

<style scoped lang="scss">
.MediaViewer{
  &.gallery-view {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      gap: 10px;
      justify-items: center;
      width: 100%;
  
      .MediaGroup {
        display: contents; // Para que cada MediaItem tome el grid
      }
  
      .MediaItem {
        width: 100%;
        height: auto;
        aspect-ratio: 4 / 3;
        margin: 0;
        box-shadow: 0 0 5px #ddd;
        transition: box-shadow 0.3s ease;
  
        &:hover {
          box-shadow: 0 0 10px #bbb;
        }
  
        &__Overlay {
          opacity: 0;
          background-color: rgba(0, 0, 0, 0.4);
          transition: opacity 0.3s ease;
        }
  
        &--title {
          display: none; // Ocultar títulos en la vista de galería
        }
      }
  
      .MediaItem__Videoyt {
        width: 100%;
        height: auto;
        aspect-ratio: 16 / 9; // Mantener una proporción de video típica
  
        .MediaItem {
          width: 100%;
          padding-top: 0;
          background-position: center;
        }
      }
    }
  
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    @media only screen and (max-width: 600px) {
    min-width: 100%;
  }
}
.MediaGroup {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  // & /deep/ #silentbox-overlay {
  //   z-index: 9999;
  // }
}

.MediaItem {
  display: block;
  width: 171px;
  height: 120px;
  margin-right: .5em;
  margin-bottom: .5em;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  box-shadow: 0 0 10px #ddd;
  position: relative;
  display: flex;

  @media screen and ( max-width: 768px ) {
    width: 48%;
    height: auto;
    padding-top: 48%;
  }
  &--title{
    width: 100%;
    font-size: 13px;
    text-align: center;
    color: white;
  }
  &__Overlay {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.6);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    display: flex;
  }

  &__TypeIcon {
    margin: auto;
    color: #fff;
  }
  &__VideoTitle{
    width: 100%;
    .MediaItem{
        width: 100%;
        height: 300px;
    }
    .MediaItem__TypeIcon{
        &::before{
            width: 30px;
            height: 30px;
        }
    }
  }
  &__Videoyt{
    .MediaItem{
        width: 500px;
        height: 300px;
    }
    .MediaItem__TypeIcon{
        &::before{
            width: 30px;
            height: 30px;
        }
    }
  }
  .MediaItem__Overlay {
      opacity: 0.5;
  }
  &:hover {
    box-shadow: 0 0 10px #bbb;

    .MediaItem__Overlay {
      opacity: 1;
      transition: all ease-in .3s;
    }
  }
}
</style>
