export const parseVideoUrl = (url) => {
  let type = 'unknown'
  let embedLink = null

  url.match(/(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/);

  if (RegExp.$3.indexOf('youtu') > -1) {
    type = 'youtube'
    embedLink = `//www.youtube.com/embed/${RegExp.$6}`
  } else if (RegExp.$3.indexOf('vimeo') > -1) {
    type = 'vimeo'
    embedLink = `//player.vimeo.com/video/${RegExp.$6}`
  }

  return {
    id: RegExp.$6,
    type: type,
    embedLink: embedLink
  }
}
